import { Box } from "@mui/material";
import { MASTER_CLIENT_FEATURE } from "commonlib";
import AuthServices from "services/AuthServices";
import { useMasterClientID } from "stores/authStore";
import ReportAutomationDashboard from "./report-automation/ReportAutomationDashboard";
import TaxFilingAutomationDashboard from "./tax-filing-automation/TaxFilingAutomationDashboard";
export default function Dashboard() {
  useMasterClientID();
  return (
    <Box sx={{ m: 2 }}>
      {AuthServices.isFeatureEnabled(
        MASTER_CLIENT_FEATURE.REPORT_AUTOMATION
      ) && <ReportAutomationDashboard />}

      {AuthServices.isFeatureEnabled(
        MASTER_CLIENT_FEATURE.TAX_FILING_AUTOMATION
      ) && <TaxFilingAutomationDashboard />}
    </Box>
  );
}
